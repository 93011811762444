import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Socket } from 'ngx-socket-io';
import { ToastrService } from 'ngx-toastr';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { BaseComponent } from 'src/app/models/base-component';
import { Util } from 'src/app/utils/utils';
import { RedesSocialesService } from '../../services/redes-sociales/redes-sociales.service';

declare const qr:any

@Component({
  selector: 'app-whatsapp-configuracion',
  templateUrl: './whatsapp-configuracion.component.html',
  styleUrls: ['./whatsapp-configuracion.component.css']
})
export class WhatsappConfiguracionComponent extends BaseComponent implements OnInit {

  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();

  codigo_qr:string;
  sucursales_usuario:any[]=[];
  empresa:any;

  constructor(public persistenciaService:PersistenciaService,
    public generalService:GeneralService,
    private socket: Socket,
    public modalService: NgbModal,
    private toastr: ToastrService,
    public usuarioService:UsuarioService,
    public redesSocialesService:RedesSocialesService) {
    super(persistenciaService,modalService,generalService,usuarioService);
  }

  ngOnInit(): void {
    this.empresa.codigo_qr=null;
    let me=this;
    this.socket.on('enviarCodigoQrSucursal', (dato) => { console.log(dato);
      //me.blockUI.start();
      if(me.usuario.empresa.id==dato.id_empresa){
        this.empresa.codigo_qr=dato.qr;
      }
      //me.blockUI.stop();
    });

    this.socket.on('clienteIniciado', (dato) => { console.log(dato);
      if(dato.id_empresa==me.usuario.empresa.id){
        me.usuario.empresa.conexion_redes_sociales=dato.uid;
        me.persistenciaService.set("uid_empresa_whatsapp_"+dato.id_empresa,dato.uid);
        me.toastr.success(dato.mensaje);
        me.alTerminar.emit(dato);
      }
    });

    this.socket.on('enviarChatWhatsapp', (dato) => { console.log(dato);
      this.alTerminar.emit(dato);
    });

    this.conectarClienteWhtasapp();
  }

  conectarClienteWhtasapp(){
    this.blockUI.start();
    this.redesSocialesService.solicitarConexionClienteWhatsapp({id_empresa:this.usuario.id_empresa}).subscribe((res:any)=>{
      this.blockUI.stop();
      this.toastr.success(res.mensaje);
    });
  }

  cerrarComponente(){
    this.alTerminar.emit();
  }

}
