<div class="modal-body card-body p-0" *ngIf="cliente">
    <div class="row pt-2 pl-1 pr-1 bgc-danger-d3">
        <div class="col-12 text-center">
            <span class="text-yellow text-150 ">{{cliente.identificacion}}</span>
        </div>
    </div>
    <div class="row pt-2 pl-1 pr-1 bgc-danger-d3">
        <hr>
        <div class="col-12 text-center">
            <span class="text-white text-130 ">Lista de categorias</span>
        </div>
    </div>
    <div class="row pb-5 pl-1 pr-1 text-white bgc-danger-d3">
        <div class="col-12">
            <div class="card bcard bgc-transparent shadow-none">
                <div class="card-body p-0">
                    <ul class="nav nav-tabs align-self-start" role="tablist">
                    <li class="nav-item brc-warning shadow-sm" *ngFor="let equipo of cliente.equipos">
                        <a (click)="establecerEquipoTab(equipo)" [ngClass]="{'active':equipo_tab.id==equipo.id}" class="nav-link text-left py-3" id="home14-tab-btn" data-toggle="tab" href="#home14" role="tab" aria-controls="home14" aria-selected="true">
                            {{equipo.categoria.nombre}}
                        </a>
                    </li>
                    </ul>
        
                    <div class="tab-content p-35 border-1 brc-grey-l1 shadow-sm bgc-white">
                        <div *ngFor="let equipo of cliente.equipos" [ngClass]="{'active':equipo_tab.id==equipo.id}" class="tab-pane fade show text-95" id="home14" role="tabpanel" aria-labelledby="home14-tab-btn">
                            <div class="row">
                                <div class="col-12 col-md-6 p-1">
                                    <div class="card bcard overflow-hidden shadow text-center">
                                        <div class="card-body p-3 px bgc-orange-d2">
                                        <div class="row">
                                            <div class="col-3 text-center">
                                                <img class="pull-left" src="{{rest_server+equipo.logo}}" width="60" height="60" />
                                            </div>
                                            <div class="col-7">
                                                <span class="text-white text-125">{{equipo.nombre}} - {{equipo.categoria.nombre}}</span><br>
                                                <span class="text-white text-125">{{equipo.disciplina.nombre}}</span>
                                            </div>
                                            <div class="col-2">
                                                <button *ngIf="equipo.habilitar_jugadores" (click)="crearNuevoJugador(equipo)" class="btn btn-success"  >
                                                    <i class="fa fa-plus text-110 mr-1"></i>
                                                </button>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    <div class="card bcard overflow-hidden shadow">
                                    <div class="card-body p-3px bgc-orange-d2">
                                        <div class="radius-1 table-responsive">
                                        <table class="table table-striped table-bordered table-hover brc-black-tp10 mb-0 text-grey">
                                            <thead class="brc-transparent">
                                            <tr class="bgc-orange-d2 text-white">
                                                <th class="text-center pr-0">
                                                    <label class="py-0">
                                                      <input [(ngModel)]="allItemsSelected" (change)="selectAllItems(equipo.jugadores)" type="checkbox" class="align-bottom mb-n1 border-2 text-dark-m3" />
                                                    </label>
                                                </th>
                                                <th>
                                                Nº
                                                </th>
                                                <th>
                                                    Foto
                                                </th>
                                                <th>
                                                Jugador
                                                </th>
                                                <th style="text-align: center; width: 140px; " data-field="tools">
                                                    <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                                                    <div class="fht-cell"></div>
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr class="bgc-h-yellow-l3 bgc-success-l2"  *ngFor="let jugador of equipo.jugadores;let i=index;">
                                                <td class='text-center pr-0 pos-rel'>
                                                    <div class="position-tl h-100 ml-n1px border-l-4 brc-orange-m1 v-hover">
                                                      <!-- border shown on hover -->
                                                    </div>
                                                    <div class="position-tl h-100 ml-n1px border-l-4 brc-success-m1">
                                                      <!-- border shown when row is selected -->
                                                    </div>
                                  
                                                    <label>
                                                      <input [(ngModel)]="jugador.selected" (change)="addItemToSelected(jugador)" type="checkbox" class="align-middle" />
                                                    </label>
                                                  </td>
                                                <td class="text-600 text-dark text-center">
                                                    {{i+1}}
                                                </td>
                                                <td class="text-110" >
                                                    <img class="pull-left" src="{{rest_server+jugador.persona.imagen}}" width="50" height="50" />
                                                </td>
                                                <td class="text-110" >
                                                    {{jugador.persona.nombre_completo}}
                                                </td>
                                                <td style="text-align: center; width: 140px; ">
                                                    <div class="action-buttons">        
                                                      <a *ngIf="equipo.habilitar_jugadores" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="modificarJugador(jugador)">          
                                                        <i class="fa fa-pencil-alt text-105"></i>        
                                                      </a>
                                                      <a *ngIf="equipo.habilitar_jugadores" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacionJugador(jugador)">          
                                                        <i class="fa fa-trash-alt text-105"></i>        
                                                      </a>      
                                                    </div>
                                                  </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        </div><!-- ./table-responsive -->
                                    </div><!-- /.card-body -->
                                    </div><!-- /.card -->
                                
                                </div><!-- /.col -->
                                <div class="col-12 col-md-6 p-1">
                                    <div class="card bcard overflow-hidden shadow text-center">
                                        <div class="card-body p-3 px bgc-orange-d2">
                                        <div class="row">
                                            <div class="col-12 text-center">
                                                <span class="text-white text-125">PARTIDOS</span>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    <div class="card bcard overflow-hidden shadow">
                                    <div class="card-body p-3px bgc-orange-d2">
                                        <div class="radius-1 table-responsive">
                                        <table class="table table-striped table-bordered table-hover brc-black-tp10 mb-0 text-grey">
                                            <thead class="brc-transparent">
                                            <tr class="bgc-orange-d2 text-white">
                                                <th>
                                                Nº
                                                </th>
                                                <th>
                                                    Fecha - Hora
                                                </th>
                                                <th>
                                                    Local
                                                </th>
                                                <th>
                                                    Marcador
                                                </th>
                                                <th>
                                                    Visitante
                                                </th>
                                                <th style="text-align: center; width: 140px; " data-field="tools">
                                                    
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr class="bgc-h-yellow-l3 bgc-success-l2"  *ngFor="let partido of partidos;let i=index;">
                                                <td class="text-600 text-dark text-center">
                                                    {{i+1}}
                                                </td>
                                                <td class="text-600 text-center">
                                                    {{partido.fecha_hora | date:'dd/MM/yyy : HH:mm'}}
                                                </td>
                                                <td class="text-110" >
                                                    <img class="pull-left" src="{{rest_server+partido.equipo_1.logo}}" width="50" height="50" />
                                                    {{partido.equipo_1.nombre}}
                                                </td>
                                                <td class="text-110" >
                                                    <span [ngClass]="{'bgc-danger-l3 brc-danger-m3':partido.marcador_equipo_1<partido.marcador_equipo_2,'bgc-success-l3 brc-success-m3':partido.marcador_equipo_2<partido.marcador_equipo_1,'bgc-primary-l3 brc-primary-m3':partido.marcador_equipo_2==partido.marcador_equipo_1}" class="m-1 border-1 text-dark-tp3 text-180 px-25 py-3px radius-round">
                                                        {{partido.marcador_equipo_1}}
                                                    </span>
                                                    VS
                                                    <span [ngClass]="{'bgc-danger-l3 brc-danger-m3':partido.marcador_equipo_2<partido.marcador_equipo_1,'bgc-success-l3 brc-success-m3':partido.marcador_equipo_1<partido.marcador_equipo_2,'bgc-primary-l3 brc-primary-m3':partido.marcador_equipo_1==partido.marcador_equipo_2}" class="m-1 border-1 text-dark-tp3 text-180 px-25 py-3px radius-round">
                                                        {{partido.marcador_equipo_2}}
                                                    </span>
                                                </td>
                                                <td class="text-110" >
                                                    {{partido.equipo_2.nombre}}
                                                    <img class="pull-left" src="{{rest_server+partido.equipo_2.logo}}" width="50" height="50" />
                                                </td>
                                                <td>
                                                    <button title="Lista de Convocados" (click)="generarListaConvocados(selectedItems,partido)" class="btn btn-warning"  >
                                                        <i class="fas fa-images text-110 mr-1"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        </div><!-- ./table-responsive -->
                                    </div><!-- /.card-body -->
                                    </div><!-- /.card -->
                                
                                </div><!-- /.col -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>