import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalVariable } from 'src/app/global';

@Injectable({
  providedIn: 'root'
})
export class LaboratoriosService {

  constructor(private http: HttpClient) { }

  public obtenerListaLaboratorios(paginator){
    let fecha_inicial=new Date(paginator.filter.fecha_inicio.year,paginator.filter.fecha_inicio.month-1,paginator.filter.fecha_inicio.day,0,0);
    let fecha_fin=new Date(paginator.filter.fecha_fin.year,paginator.filter.fecha_fin.month-1,paginator.filter.fecha_fin.day,0,0);
    return this.http.get(GlobalVariable.API_SERVER_URL+"laboratorios/empresa/"+paginator.filter.id_empresa+"/inicio/"+fecha_inicial+"/fin/"+fecha_fin+"/busqueda/"+paginator.search+"/columna/"+paginator.column+"/direccion/"+paginator.direction,GlobalVariable.httpOptions);
  }

  public guardarLaboratorio(laboratorio){
    return this.http.post(GlobalVariable.API_SERVER_URL+"laboratorios/",laboratorio,GlobalVariable.httpOptions);
  }

  public obtenerLaboratorio(id_laboratorio){
    return this.http.get(GlobalVariable.API_SERVER_URL+"laboratorios/"+id_laboratorio,GlobalVariable.httpOptions);
  }

  public actualizarLaboratorio(laboratorio){
    return this.http.put(GlobalVariable.API_SERVER_URL+"laboratorios/"+laboratorio.id,laboratorio,GlobalVariable.httpOptions);
  }

  public eliminarLaboratorio(laboratorio){
    return this.http.patch(GlobalVariable.API_SERVER_URL+"laboratorios/"+laboratorio.id,laboratorio,GlobalVariable.httpOptions);
  }
}
