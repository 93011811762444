import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { ClientesService } from 'src/app/snapquick/services/clientes/clientes.service';
import { Util } from 'src/app/utils/utils';
import { CampeonatosService } from '../../services/campeonatos/campeonatos.service';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';

declare const aplicarSubidaArchivo:any;

@Component({
  selector: 'app-registro-jugador',
  templateUrl: './registro-jugador.component.html',
  styleUrls: ['./registro-jugador.component.css']
})
export class RegistroJugadorComponent extends BaseComponent implements OnInit {

  jugador:any={persona:{}};
  equipo:any;
  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();
  imageChangedEvent: any = '';
  croppedImage: any = '';

  constructor(
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public usuarioService:UsuarioService,
    public modalService: NgbModal,
    private toastr: ToastrService,
    public clientesService:ClientesService,
    public campeonatosService:CampeonatosService) { 
      super(persistenciaService,modalService,generalService,usuarioService);
  }

  async ngOnInit(): Promise<void> {    
    if(this.jugador.id){

    }else{
      let codigo_nuevo=await this.obtenerUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_JUGADOR);
      this.jugador={   
          id_empresa:this.usuario.id_empresa,   
          id_equipo: this.equipo.id,
          codigo:codigo_nuevo,
          activo:true,
          persona:{
            activo:true
          }
      }
    }
    //aplicarSubidaArchivo('foto-jugador','preview-template-foto',this,'subirArchivoFoto','eliminarArchivoFoto',{maximo_tamano_archivo:10});
  }

  validarFormularioRegistroJugador(){
		let res=true;
		if(!this.jugador.persona.nombres){
			this.toastr.error("Debe especificar el nombre del jugador!");
			res=false;
		}
		return res;
	}

  guardarJugador(){
		if(this.validarFormularioRegistroJugador()){
			this.blockUI.start();
      this.jugador.persona.fecha_nacimiento=this.jugador.persona.fecha_nacimiento_texto?Util.convertirObjetoAfecha(this.jugador.persona.fecha_nacimiento_texto):null;
			if(this.jugador.id){
				this.campeonatosService.actualizarJugador(this.jugador).subscribe((res:any) => {
					this.blockUI.stop();
          this.imageChangedEvent=null;
					this.toastr.success(res.mensaje);
					this.alTerminar.emit(res);
				});
			}else{
				this.campeonatosService.guardarJugador(this.jugador).subscribe((res:any) => {
          this.actualizarUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_JUGADOR);
					this.blockUI.stop();
          this.imageChangedEvent=null;
					this.toastr.success(res.mensaje);
					this.alTerminar.emit(res);
				});
			}
		}
		
	}

  subirArchivoFoto(file){
    let me=this;
		var fileToLoad = file;
			var fileReader = new FileReader();

			fileReader.onloadend = function() 
			{
				let base64EncodedImage = fileReader.result; // <--- data: base64
        me.jugador.persona.imagen=base64EncodedImage;
			}
			fileReader.readAsDataURL(fileToLoad);	
  }

  eliminarArchivoFoto(file){
    let me=this;
		var fileToLoad = file;
			var fileReader = new FileReader();

			fileReader.onloadend = function() 
			{
				let base64EncodedImage = fileReader.result; // <--- data: base64
        me.jugador.persona.imagen=null;
			}
			fileReader.readAsDataURL(fileToLoad);	
  }
  
  cerrarComponente(){
    this.alTerminar.emit();
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    //this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl);
    // event.blob can be used to upload the cropped image
    this.jugador.persona.imagen=event.base64;
  }

  imageLoaded(image: LoadedImage) {
    // show cropper
  }
  cropperReady() {
      // cropper ready
  }
  loadImageFailed() {
      // show message
  }

}
