import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { PacientesService } from '../../services/pacientes/pacientes.service';
import { BaseComponent } from 'src/app/models/base-component';
import { RegistroPacienteComponent } from '../registro-paciente/registro-paciente.component';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { Util } from 'src/app/utils/utils';
import { ProductosService } from 'src/app/snapquick/services/productos/productos.service';
import { LaboratoriosService } from '../../services/laboratorios/laboratorios.service';
declare const $:any;
declare const aplicarSubidaArchivo:any;

@Component({
  selector: 'app-registro-laboratorio',
  templateUrl: './registro-laboratorio.component.html',
  styleUrls: ['./registro-laboratorio.component.css']
})
export class RegistroLaboratorioComponent extends BaseComponent implements OnInit {

  laboratorio:any={paciente:{cliente:{}},pruebas:[],solicitud:null,resultado:null};
  dato_paciente_lista:boolean=false;
  pacientes:any[]=[];
  modalRegistroPaciente:NgbModalRef;
  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();
  searching = false;
  searchFailed = false;
  util:any=Util;
  producto_busqueda:any;

  inhabilitar_paciente:boolean=false;
  inhabilitar_busqueda_item:boolean=false;
  inhabilitar_eliminacion_detalle:boolean=false;
  inhabilitar_fecha:boolean=false;

  constructor(
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public usuarioService:UsuarioService,
    public modalService: NgbModal,
    private toastr: ToastrService,
    public productosService:ProductosService,
    private pacientesService:PacientesService,
    private laboratoriosService:LaboratoriosService) { 
      super(persistenciaService,modalService,generalService,usuarioService);
    }

  ngOnInit(): void {
    if(this.laboratorio.id){
      let fecha_hora=new Date(this.laboratorio.fecha_hora);
      this.laboratorio.fecha_texto={
        year: fecha_hora.getFullYear(), 
        month: (fecha_hora.getMonth()+1),
        day:fecha_hora.getDate()
      }
    }else{
      let fecha_hora_actual=new Date();
      this.laboratorio.fecha_texto={
        year: fecha_hora_actual.getFullYear(), 
        month: (fecha_hora_actual.getMonth()+1),
        day:fecha_hora_actual.getDate()
      }
    }
    this.laboratorio.id_empresa=this.usuario.id_empresa;
    this.laboratorio.id_sucursal=this.usuario.sucursalesUsuario[0].id_sucursal;
    this.obtenerPacientes();
  }

  ngAfterViewInit(){
    aplicarSubidaArchivo('form-documentos-laboratorio','template-documentos-laboratorio',this,'subirArchivo','eliminarArchivo',{maximo_tamano_archivo:2});
    aplicarSubidaArchivo('form-resultado-laboratorio','template-resultado-laboratorio',this,'subirArchivoResultado','eliminarArchivoResultado',{maximo_tamano_archivo:2});
  }

  cerrarComponente(){
    this.alTerminar.emit();
  }

  cambiarPresentacionPaciente(){
    this.dato_paciente_lista=!this.dato_paciente_lista;
  }

  obtenerPacientes(){
    this.pacientesService.obtenerPacientesEmpresa(this.usuario.id_empresa).subscribe((res:any)=>{
      this.pacientes=res;
    });
  }

  crearNuevoPaciente(){
		this.modalRegistroPaciente = this.modalService.open(RegistroPacienteComponent, { ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
			this.modalRegistroPaciente.componentInstance.usuario = this.usuario;
			this.modalRegistroPaciente.componentInstance.alTerminar.subscribe((res) => {
				if(res){
					if(res.tiene_error){
						
					}else{
            this.obtenerPacientes();
            this.laboratorio.paciente=res.paciente;
						this.modalRegistroPaciente.close();
					}
				}else{
					this.modalRegistroPaciente.close();
				}
				
			});
  }

  buscarPaciente = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
	  switchMap( (term) =>  this.pacientesService.buscarPaciente(this.usuario.id_empresa,term)),
	  catchError(() => {
		this.searchFailed = true;
		return of([]);
	  })  ,
      tap(() => this.searching = false)
	)
	
  formatter = (x: any) => (x.id?(x.cliente.identificacion?x.cliente.identificacion:""):"");

  establecerPaciente(event){
    let paciente=(event.id)?event:event.item;
    this.laboratorio.paciente_texto=paciente.cliente.idetificacion;
    this.laboratorio.paciente=paciente;
  }

  reiniciarPaciente(){
    this.laboratorio.paciente={cliente:{}};
    this.laboratorio.paciente_texto=null;
    $("#paciente_texto").val("");
  }

  modificarPaciente(paciente){
		this.modalRegistroPaciente = this.modalService.open(RegistroPacienteComponent, { ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
			this.modalRegistroPaciente.componentInstance.usuario = this.usuario;
      this.modalRegistroPaciente.componentInstance.paciente = paciente;
			this.modalRegistroPaciente.componentInstance.alTerminar.subscribe((res) => {
				if(res){
					if(res.tiene_error){
						
					}else{
            this.obtenerPacientes();
            this.laboratorio.paciente=res.paciente;
						this.modalRegistroPaciente.close();
					}
				}else{
					this.modalRegistroPaciente.close();
				}
				
			});
  }

  buscarProducto = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
	  switchMap( (termp) =>  this.productosService.buscarProducto(this.usuario.id_empresa,termp)),
	  catchError(() => {
		this.searchFailed = true;
		return of([]);
	  })  ,
      tap(() => this.searching = false)
	)
	
  formatterProducto = (x: any) => (x?(x.codigo+'-'+x.nombre+'-'+(x.grupo?x.grupo.nombre:'')+'-'+(x.subgrupo?x.subgrupo.nombre:'')):"");

  establecerProducto($event){
    this.laboratorio.producto=$event.item
  }

  iniciarAdhseionLaboratorio(){
    this.producto_busqueda="";
    this.laboratorio.producto={};
    $("#id_producto").val("");
  }

  agregarPrueba(){
    this.laboratorio.pruebas.push({prueba:this.laboratorio.producto});
    this.iniciarAdhseionLaboratorio();
  }

  sumarPrecios(){
    let suma=0;
    for(let i=0;i<this.laboratorio.pruebas.length;i++){
      if(!this.laboratorio.pruebas[i].eliminado){
        suma=suma+this.laboratorio.pruebas[i].prueba.precio_unitario;
      }
    }
    return suma.toFixed(2);
  }

  validarLaboratorio(){
    let res=true;
    if(!this.laboratorio.paciente.cliente.id){
      res=res && false;
      this.toastr.error("Debe especificar el nombre del paciente!");
    }
    if(this.laboratorio.fecha_texto && (!this.laboratorio.fecha_texto.year || !this.laboratorio.fecha_texto.month || !this.laboratorio.fecha_texto.day)){
      res=res && false;
      this.toastr.error("Debe especificar la fecha de rregistro completa!");
    }
    if(this.laboratorio.pruebas.length==0){
      res=res && false;
      this.toastr.error("Debe especificar al menos una prueba de laboratorio!");
    }
    return res;
  }

  guardarLaboratorio(){
    if(this.validarLaboratorio()){
      this.blockUI.start();
      this.laboratorio.fecha_hora=this.laboratorio.fecha_texto?Util.convertirObjetoAfecha(this.laboratorio.fecha_texto):null;
      if (this.laboratorio.id) {
        this.laboratoriosService.actualizarLaboratorio(this.laboratorio).subscribe((res:any) => {
          this.blockUI.stop();
          this.toastr.success(res.mensaje);
          this.alTerminar.emit(res);
        });
      } else {
        this.laboratoriosService.guardarLaboratorio(this.laboratorio).subscribe((res:any) => {
          this.blockUI.stop();
          this.toastr.success(res.mensaje);
          this.alTerminar.emit(res);
        });
      }
    }
  }

  eliminarPrueba(prueba){
    prueba.eliminado=true;
  }

  subirArchivo(file){
    let me=this;
		var fileToLoad = file;
			var fileReader = new FileReader();

			fileReader.onloadend = function() 
			{
				let base64EncodedImage = fileReader.result; // <--- data: base64
        me.laboratorio.solicitud=base64EncodedImage;
			}
			fileReader.readAsDataURL(fileToLoad);	
  }

  eliminarArchivo(file){
    let me=this;
		var fileToLoad = file;
			var fileReader = new FileReader();

			fileReader.onloadend = function() 
			{
				let base64EncodedImage = fileReader.result; // <--- data: base64
        me.laboratorio.solicitud=null;
			}
			fileReader.readAsDataURL(fileToLoad);	
  }

  subirArchivoResultado(file){
    let me=this;
		var fileToLoad = file;
			var fileReader = new FileReader();

			fileReader.onloadend = function() 
			{
				let base64EncodedImage = fileReader.result; // <--- data: base64
        me.laboratorio.resultado=base64EncodedImage;
			}
			fileReader.readAsDataURL(fileToLoad);	
  }

  eliminarArchivoResultado(file){
    let me=this;
		var fileToLoad = file;
			var fileReader = new FileReader();

			fileReader.onloadend = function() 
			{
				let base64EncodedImage = fileReader.result; // <--- data: base64
        me.laboratorio.resultado=null;
			}
			fileReader.readAsDataURL(fileToLoad);	
  }

}
