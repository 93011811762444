import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { Util } from 'src/app/utils/utils';
import { RegistroLaboratorioComponent } from '../registro-laboratorio/registro-laboratorio.component';
import { LaboratoriosService } from '../../services/laboratorios/laboratorios.service';
import { PopupConfirmacionComponent } from 'src/app/base/components/popup-confirmacion/popup-confirmacion.component';
import { VistaLaboratorioComponent } from '../vista-laboratorio/vista-laboratorio.component';
import { VentaComponent } from 'src/app/base/components/venta/venta.component';

declare const resaltarPestañaMenu:any;
declare const PDFDocument:any;
declare const blobStream:any;

@Component({
  selector: 'app-laboratorios',
  templateUrl: './laboratorios.component.html',
  styleUrls: ['./laboratorios.component.css']
})
export class LaboratoriosComponent extends BaseComponent implements OnInit {

  modalRegistroLaboratorio:NgbModalRef;
  modalVenta:NgbModalRef;
  laboratorios:any=[];

  constructor(
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public usuarioService:UsuarioService,
    public modalService: NgbModal,
    private toastr: ToastrService,
	private laboratoriosService:LaboratoriosService,
    private router: Router) { 
      super(persistenciaService,modalService,generalService,usuarioService);
  }

  async ngOnInit(): Promise<void> {
    this.init();
    this.buscarAplicacion(this.router.url.substring(1));
    let fecha_actual=new Date();
    this.filter={
			id_empresa:this.usuario.id_empresa,
			ids_sucursales_usuario:Util.obtenerIdsSucursales(this.usuario),
			sucursales:[GlobalVariable.Dictionary.SELECCION_TODOS].concat(Util.obtenerSucursalesUsuario(this.usuario)),
			sucursal: GlobalVariable.Dictionary.SELECCION_TODOS,
			usuarios:[{nombre_usuario:"TODOS",id:0}],
			usuario: {nombre_usuario:"TODOS",id:0},
			activas:[GlobalVariable.Dictionary.SELECCION_TODOS,{id:1,nombre:"ACTIVAS"},{id:2,nombre:"ANULADAS"}],
			activa: GlobalVariable.Dictionary.SELECCION_TODOS,
			fecha_inicio:{
				year: fecha_actual.getFullYear(), 
				month: (fecha_actual.getMonth()+1),
				day:fecha_actual.getDate()
			},
			fecha_fin:{
				year: fecha_actual.getFullYear(), 
				month: (fecha_actual.getMonth()+1),
				day:fecha_actual.getDate()
			}
		}
    await this.obtenerConfiguracionPagina(GlobalVariable.Dictionary.CODIGO_APP_PRUEBAS_CLINICAS);

    this.column = "fecha_hora";
	this.direction = "desc";
	this.itemsPerPage=0;
	this.getSearch(this.text_search,null);
  }

  ngAfterViewInit() {
		resaltarPestañaMenu(this.router.url.substring(1)+"1");
  }

  	crearRegistroLaboratorio(){
    	this.modalRegistroLaboratorio = this.modalService.open(RegistroLaboratorioComponent, {scrollable:true, windowClass:'registro-laboratorio', ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
		this.modalRegistroLaboratorio.componentInstance.usuario = this.usuario;
		this.modalRegistroLaboratorio.componentInstance.configuracion_pagina = this.configuracion_pagina.object;
		this.modalRegistroLaboratorio.componentInstance.alTerminar.subscribe(async (res) => {
			if(res){
				if(!res.tiene_error){
					this.modalRegistroLaboratorio.close();
				}
			}else{
				this.modalRegistroLaboratorio.close();
			}
		});
	}

  	async getItems(){
		this.blockUI.start();
		this.laboratoriosService.obtenerListaLaboratorios(this).subscribe((laboratorios:any)=>{
		this.blockUI.stop();
		this.laboratorios=laboratorios;
		});
	}

	modificarLaboratorio(laboratorio){
		this.blockUI.start();
		this.laboratoriosService.obtenerLaboratorio(laboratorio.id).subscribe((res_laboratorio:any)=>{
			this.blockUI.stop();
			this.modalRegistroLaboratorio = this.modalService.open(RegistroLaboratorioComponent, {scrollable:true, windowClass:'registro-laboratorio', ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
			this.modalRegistroLaboratorio.componentInstance.laboratorio = res_laboratorio;
			this.modalRegistroLaboratorio.componentInstance.inhabilitar_paciente = true;
			this.modalRegistroLaboratorio.componentInstance.inhabilitar_busqueda_item = laboratorio.id_venta?true:false;
			this.modalRegistroLaboratorio.componentInstance.inhabilitar_eliminacion_detalle = laboratorio.id_venta?true:false;
			this.modalRegistroLaboratorio.componentInstance.inhabilitar_fecha = laboratorio.id_venta?true:false;
			this.modalRegistroLaboratorio.componentInstance.usuario = this.usuario;
			this.modalRegistroLaboratorio.componentInstance.configuracion_pagina = this.configuracion_pagina.object;
			this.modalRegistroLaboratorio.componentInstance.alTerminar.subscribe(async (res) => {
				if(res){
					if(!res.tiene_error){
						this.modalRegistroLaboratorio.close();
					}
				}else{
					this.modalRegistroLaboratorio.close();
				}
			});
		});
	}

	abrirPopupConfirmacionEliminacion(laboratorio){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro(a) de anular el examen de laboratorio?";
		this.popupConfirmacion.componentInstance.data = laboratorio;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarLaboratorio($e.data);
			}
			this.popupConfirmacion.close();
    });
	}

	eliminarLaboratorio(laboratorio){
		this.blockUI.start();
		this.laboratoriosService.eliminarLaboratorio(laboratorio).subscribe((res:any) => {
			this.toastr.success(res.mensaje);
			this.getItems();
			this.blockUI.stop();
		});
	}

	async generarPdfSolicitudLaboratorio(laboratorio_o){
		let laboratorio:any=await this.laboratoriosService.obtenerLaboratorio(laboratorio_o.id).toPromise();
		var doc = new PDFDocument({margin:10,size:GlobalVariable.Dictionary.PAPEL_CARTA_MEDIDA});
		var stream = doc.pipe(blobStream());
		let fecha_inicio=new Date();
		let fecha_fin=new Date();
		fecha_fin.setDate(fecha_inicio.getDate()+365-1);
		doc.font('Helvetica',10);
		doc.fillColor("blue");
		//doc.text("DR. HENRY MORUNO CRUZ MEDICO REUMATOLOGO"/*this.usuario.empresa.nombre*/,231,30,{width:150,align:"center"});
		doc.font('Helvetica-Bold',20);
		doc.fillColor("black");
		doc.text("SOLICITUD DE ESTUDIOS DE LABORATORIO",0,60,{align:"center"});
		doc.font('Helvetica-Bold',12);
		doc.text("NOMBRE:",50,90);
		doc.text("EDAD: ",50,110);
		doc.text("TELEFONO: ",50,130);
		doc.text("FECHA:",450,90);
		doc.text("- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ",0,140,{align:'center'});
		doc.font('Helvetica',12);
		let texto_nombre=(laboratorio.paciente.cliente.persona.nombres+" "+(laboratorio.paciente.cliente.persona.apellido_paterno?laboratorio.paciente.cliente.persona.apellido_paterno:"")+" "+(laboratorio.paciente.cliente.persona.apellido_materno?laboratorio.paciente.cliente.persona.apellido_materno:""));
		doc.text(texto_nombre.toUpperCase(),120,90);
		doc.text((Util.calcularEdad(laboratorio.paciente.cliente.persona.fecha_nacimiento))+" Años",120,110);
		let telefono=(laboratorio.paciente.cliente.persona.telefono?laboratorio.paciente.cliente.persona.telefono+"-":"")+(laboratorio.paciente.cliente.persona.telefono_movil?laboratorio.paciente.cliente.persona.telefono_movil:"");
		doc.text(telefono,120,130);
		let fecha_hoy=new Date();
		doc.text((fecha_hoy.getDate()+"-"+(fecha_hoy.getMonth()+1)+"-"+fecha_hoy.getFullYear()),500,90);

		doc.font('Helvetica-Bold',10);
		let y_p=180;
		doc.text("Nº",60,y_p);
		doc.text("PRUEBA",80,y_p);
		doc.text("TIPO",360,y_p);
		y_p=y_p+40;
		doc.font('Helvetica',10);
		for(let i=0;i<laboratorio.pruebas.length;i++){
			doc.text((i+1)+".-",60,y_p);
			doc.text(laboratorio.pruebas[i].prueba.nombre,80,y_p);
			doc.text(laboratorio.pruebas[i].prueba.subgrupo.nombre,360,y_p);
			y_p=y_p+40;
		}

		doc.text("Observaciones: "+(laboratorio.observacion?laboratorio.observacion:""),80,700);

		doc.end();
	
		stream.on('finish', function () {
		  var fileURL = stream.toBlobURL('application/pdf');
		  var w = window.open(fileURL, '_blank', 'location=no');
		  setTimeout(function () {
			w.print();
		  }, 500);
		});
	}

	verLaboratorio(laboratorio){
		this.blockUI.start();
		this.laboratoriosService.obtenerLaboratorio(laboratorio.id).subscribe((res_laboratorio:any)=>{
			this.blockUI.stop();
			this.modalRegistroLaboratorio = this.modalService.open(VistaLaboratorioComponent, {windowClass:'vista-laboratorio',scrollable:true, ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
			this.modalRegistroLaboratorio.componentInstance.laboratorio = res_laboratorio;
			this.modalRegistroLaboratorio.componentInstance.usuario = this.usuario;
			this.modalRegistroLaboratorio.componentInstance.configuracion_pagina = this.configuracion_pagina.object;
			this.modalRegistroLaboratorio.componentInstance.alTerminar.subscribe(async (res) => {
				if(res){
					if(!res.tiene_error){
						this.modalRegistroLaboratorio.close();
					}
				}else{
					this.modalRegistroLaboratorio.close();
				}
			});
		});
	}

	async generarVentaLaboratorio(laboratorio_dato){
		this.blockUI.start();
		this.laboratoriosService.obtenerLaboratorio(laboratorio_dato.id).subscribe(async (laboratorio:any)=>{
			let configuracion_aplicacion_ventas:any=await this.usuarioService.obtenerConfiguracionPaginaPorCodigo(this.usuario.id_empresa,GlobalVariable.Dictionary.CODIGO_APP_VENTAS,this.usuario.empresa.usar_permisos_avanzados_usuarios?this.usuario.id:0).toPromise();
			this.modalVenta = this.modalService.open(VentaComponent, {windowClass:'venta-popup', ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
			this.modalVenta.componentInstance.venta.usuario = this.usuario;
			this.modalVenta.componentInstance.usuario = this.usuario;
			this.modalVenta.componentInstance.configuracion_pagina = configuracion_aplicacion_ventas;
			this.modalVenta.componentInstance.venta.sucursales = Util.obtenerSucursalesUsuario(this.usuario);
			this.modalVenta.componentInstance.es_administrador=Util.esUsuarioAdministrador(this.usuario);
			this.modalVenta.componentInstance.venta.cliente=laboratorio.paciente.cliente;
			this.modalVenta.componentInstance.venta.sucursal=Util.obtenerSucursalesUsuario(this.usuario)[0];
			this.modalVenta.componentInstance.nombre_corto_canal_directo=this.global_variable.Dictionary.CANAL_VENTA_DIRECTA;
			this.modalVenta.componentInstance.venta.descontar_almacen=this.usuario.empresa.usar_inventario?true:false;
			this.modalVenta.componentInstance.venta.id_examen_laboratorio=laboratorio.id;
			let total=0;
			for(let i=0;i<laboratorio.pruebas.length;i++){
				total=total+laboratorio.pruebas[i].prueba.precio_unitario;
				this.modalVenta.componentInstance.venta.detallesVenta.push({
					//descripcion:cita_medica.tipo.nombre,
					producto:laboratorio.pruebas[i].prueba,
					precio_unitario:laboratorio.pruebas[i].prueba.precio_unitario,
					importe:laboratorio.pruebas[i].prueba.precio_unitario,
					total:laboratorio.pruebas[i].prueba.precio_unitario,
					es_servicio:false,
					es_combo:false,
					item:GlobalVariable.Dictionary.ITEM_PRODUCTO,
					editar_precio:true,
					cantidad:1,descuento:0,tipo_descuento:false
				});
			}
			this.modalVenta.componentInstance.venta.pagado=total;
			this.modalVenta.componentInstance.inhabilitar_busqueda_item=false;
			this.modalVenta.componentInstance.inhabilitar_sucursal=true;
			this.modalVenta.componentInstance.inhabilitar_almacen=true;
			this.modalVenta.componentInstance.inhabilitar_descontar_almacen=true;
			this.blockUI.stop();
			this.modalVenta.componentInstance.alTerminar.subscribe((res) => {
				if(res){
					if(res.tiene_error){
						this.toastr.error(res.mensaje);
						this.toastr.error(res.stack.substring(0,500));
					}else{
						this.getItems();
						this.toastr.success(res.mensaje);
						//this.imprimirVenta(res.venta.id,true);
						this.modalVenta.close();
					}
				}else{
					this.modalVenta.close();
				}
			});	
		});
	}

}
