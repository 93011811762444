<div class="modal-header card-header">
    <div class="col-md-8 col-xs-8">
        <span class="text-blue text-125">Laboratorio</span>
    </div>
    <div class="col-md-6 col-xs-6">
        <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
            <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <div class="row p-1">
        <div class="col-md-6">
            <div class="row">
                <div class="col-6 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fa fa-user red2 text-110 mr-1"></i> Paciente : 
                    </label>    
                </div>
                <div class="col-6">
                    <label>
                        {{laboratorio.paciente.cliente.persona.nombre_completo}}
                    </label>    
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="row">
                <div class="col-6 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fa fa-user red2 text-110 mr-1"></i> Edad : 
                    </label>    
                </div>
                <div class="col-6">
                    <label>
                        {{laboratorio.paciente.cliente.persona.fecha_nacimiento?util.calcularEdad(laboratorio.paciente.cliente.persona.fecha_nacimiento):''}}
                    </label>    
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="row">
                <div class="col-6 alert alert-info pr-0 mb-0">  
                    <label class="text-primary p-0">
                        <i class="fa fa-user red2 text-110 mr-1"></i> Num. Doc. : 
                    </label>  
                </div>
                <div class="col-6">
                    <label>
                        {{laboratorio.correlativo}}
                    </label>    
                </div>
            </div>
        </div>
    </div>
    <div class="row p-1">
        <div class="col-md-4">
            <div class="row">
                <div class="col-6 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-calendar"></i> Fecha : 
                    </label>    
                </div>
                <div class="col-6">
                    <label>
                        {{laboratorio.fecha_hora | date:"dd/MM/yyyy"}}
                    </label>    
                </div>
            </div>
        </div>
        <div class="col-md-4" *ngIf="laboratorio.solicitud">
            <div class="row">
                <div class="col-8 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-file-pdf"></i> Solicitud : 
                    </label>    
                </div>
                <div class="col-4">
                    <a title="Solicitud" class="btn btn-md" [ngClass]="{'btn-purple':(laboratorio.solicitud.includes('.zip') || laboratorio.solicitud.includes('.rar')),'btn-danger':laboratorio.solicitud.includes('.pdf'),'btn-success':(laboratorio.solicitud.includes('.xls') || laboratorio.solicitud.includes('.xlsx')),'btn-primary':(laboratorio.solicitud.includes('.doc') || laboratorio.solicitud.includes('.docx') || laboratorio.solicitud.includes('.jpg') || laboratorio.solicitud.includes('.png') || laboratorio.solicitud.includes('.gif') || laboratorio.solicitud.includes('.jpeg') || laboratorio.solicitud.includes('.mp3') || laboratorio.solicitud.includes('.mpeg') || laboratorio.solicitud.includes('.mp4'))}" target="_blank" href="{{rest_server+laboratorio.solicitud}}">
                        <i class="fas" [ngClass]="{'fa-file-archive':(laboratorio.solicitud.includes('.zip') || laboratorio.solicitud.includes('.rar')),'fa-file-pdf':laboratorio.solicitud.includes('.pdf'),'fa-file-excel':(laboratorio.solicitud.includes('.xls') || laboratorio.solicitud.includes('.xlsx')),'fa-file-word':(laboratorio.solicitud.includes('.doc') || laboratorio.solicitud.includes('.docx')),'fa-file-image':(laboratorio.solicitud.includes('.jpg') || laboratorio.solicitud.includes('.png') || laboratorio.solicitud.includes('.gif') || laboratorio.solicitud.includes('.jpeg')),'fa-file-audio':(laboratorio.solicitud.includes('.mp3') || laboratorio.solicitud.includes('.mpeg')),'fa-file-video':(laboratorio.solicitud.includes('.mp4'))}"></i>
                    </a>
                </div>
            </div>
        </div>
        <div class="col-md-4" *ngIf="laboratorio.resultado">
            <div class="row">
                <div class="col-8 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-file-pdf"></i> Resultados : 
                    </label>    
                </div>
                <div class="col-4">
                    <a title="Resultado" class="btn btn-md" [ngClass]="{'btn-purple':(laboratorio.resultado.includes('.zip') || laboratorio.resultado.includes('.rar')),'btn-danger':laboratorio.resultado.includes('.pdf'),'btn-success':(laboratorio.resultado.includes('.xls') || laboratorio.resultado.includes('.xlsx')),'btn-primary':(laboratorio.resultado.includes('.doc') || laboratorio.resultado.includes('.docx') || laboratorio.resultado.includes('.jpg') || laboratorio.resultado.includes('.png') || laboratorio.resultado.includes('.gif') || laboratorio.resultado.includes('.jpeg') || laboratorio.resultado.includes('.mp3') || laboratorio.resultado.includes('.mpeg') || laboratorio.resultado.includes('.mp4'))}" target="_blank" href="{{rest_server+laboratorio.resultado}}">
                        <i class="fas" [ngClass]="{'fa-file-archive':(laboratorio.resultado.includes('.zip') || laboratorio.resultado.includes('.rar')),'fa-file-pdf':laboratorio.resultado.includes('.pdf'),'fa-file-excel':(laboratorio.resultado.includes('.xls') || laboratorio.resultado.includes('.xlsx')),'fa-file-word':(laboratorio.resultado.includes('.doc') || laboratorio.resultado.includes('.docx')),'fa-file-image':(laboratorio.resultado.includes('.jpg') || laboratorio.resultado.includes('.png') || laboratorio.resultado.includes('.gif') || laboratorio.resultado.includes('.jpeg')),'fa-file-audio':(laboratorio.resultado.includes('.mp3') || laboratorio.resultado.includes('.mpeg')),'fa-file-video':(laboratorio.resultado.includes('.mp4'))}"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <hr>
    <div class="table-fixed-header" *ngIf="laboratorio.pruebas.length>0">
        <h5 class="text-blue-d2 mb-3">Detalle de pruebas</h5>
        <table class="table table-striped table-bordered table-hover">
            <thead>
                <tr>
                    <th>
                        <label>Nº</label>
                    </th>
                    <th>
                        <label>NOMBRE</label>
                    </th>
                    <th>
                        <label>CÓDIGO ITEM</label>
                    </th>
                    <th>
                        <label>SUBGRUPO</label>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let prueba of laboratorio.pruebas; let i=index;">
                    <td>
                        {{(i+1)}}
                    </td>
                    <td>
                        {{prueba.prueba.nombre}}
                    </td>
                    <td>
                        {{prueba.prueba.codigo}}
                    </td>
                    <td>
                        {{prueba.prueba.subgrupo.nombre}}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>