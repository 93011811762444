import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { BaseComponent } from 'src/app/models/base-component';
import { PacientesService } from '../../services/pacientes/pacientes.service';
import { Util } from 'src/app/utils/utils';
import { ToastrService } from 'ngx-toastr';
import { RecetasComponent } from '../recetas/recetas.component';
import { GlobalVariable } from 'src/app/global';
import { RegistroLaboratorioComponent } from '../registro-laboratorio/registro-laboratorio.component';
import { VistaLaboratorioComponent } from '../vista-laboratorio/vista-laboratorio.component';
import { LaboratoriosService } from '../../services/laboratorios/laboratorios.service';

@Component({
  selector: 'app-kardex-paciente',
  templateUrl: './kardex-paciente.component.html',
  styleUrls: ['./kardex-paciente.component.css']
})
export class KardexPacienteComponent extends BaseComponent implements OnInit {

  paciente:any={cliente:{persona:{}}}
  historias_paciente:any[]=[];
  util:any=Util;
  receta:any;
  historia_paciente:any;
  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();

  modal_registro_receta:NgbModalRef;
  modalRegistroLaboratorio:NgbModalRef;

  constructor(
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public usuarioService:UsuarioService,
    public modalService: NgbModal,
    public pacientesService:PacientesService,
    private toastr: ToastrService,
    private laboratoriosService:LaboratoriosService) { 
      super(persistenciaService,modalService,generalService,usuarioService);
  }

  ngOnInit(): void {
    this.init();
    let fecha_actual=new Date();
    this.filter={
      id_paciente:this.paciente.id,
      fecha_inicio:null,
      fecha_fin:{
        year: fecha_actual.getFullYear(), 
        month: (fecha_actual.getMonth()+1),
        day:fecha_actual.getDate()
      }
    }
  }

  cerrarComponente(){
    this.alTerminar.emit();
  }

  buscarHistorialPaciente(){
    this.blockUI.start();
    this.pacientesService.obtenerHistoriasPaciente(this).subscribe((res:any)=>{
      this.blockUI.stop();
      this.historias_paciente=res.historias_paciente;
    });
  }

  async abrirModalRegistroReceta(historia_paciente){
    historia_paciente.id=historia_paciente.id_historia_paciente;
    this.blockUI.start();
    let recetas:any=await this.pacientesService.obtenerReceta(historia_paciente.id).toPromise();
    historia_paciente.recetas=recetas;
    this.blockUI.stop();
    this.modal_registro_receta=this.modalService.open(RecetasComponent, {windowClass:'receta-paciente',size:"md",ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
    this.modal_registro_receta.componentInstance.receta =  {
      id_historia_paciente:historia_paciente.id,
      id_usuario:this.usuario.id,
      activo:true
    };
    this.modal_registro_receta.componentInstance.historia_paciente = historia_paciente;
    this.modal_registro_receta.componentInstance.paciente = this.paciente;
    this.modal_registro_receta.componentInstance.alTerminar.subscribe((res) => {
      this.modal_registro_receta.close();
    });
  }

  abrirModalRegistroLaboratorio(historia_paciente){
    historia_paciente.id=historia_paciente.id_historia_paciente;
    this.modalRegistroLaboratorio = this.modalService.open(RegistroLaboratorioComponent, {scrollable:true, windowClass:'registro-laboratorio', ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
		this.modalRegistroLaboratorio.componentInstance.usuario = this.usuario;
		this.modalRegistroLaboratorio.componentInstance.laboratorio.paciente = this.paciente;
    this.modalRegistroLaboratorio.componentInstance.inhabilitar_paciente = true;
		this.modalRegistroLaboratorio.componentInstance.alTerminar.subscribe(async (res) => {
      if(res){
				if(!res.tiene_error){
					this.pacientesService.guardarHistoriaPacienteLaboratorio({id_historia_paciente:historia_paciente.id,id_examen_laboratorio:res.examen_laboratorio.id}).subscribe((resh:any)=>{
            this.toastr.success(resh.mensaje);
            this.buscarHistorialPaciente();
          });
					this.modalRegistroLaboratorio.close();
				}
			}else{
				this.modalRegistroLaboratorio.close();
			}
		});
  }

  abrirModalVistaLaboratorio(historia_paciente){
    historia_paciente.id=historia_paciente.id_historia_paciente;

    this.blockUI.start();
		this.laboratoriosService.obtenerLaboratorio(historia_paciente.id_examen_laboratorio).subscribe((res_laboratorio:any)=>{
			this.blockUI.stop();
			this.modalRegistroLaboratorio = this.modalService.open(VistaLaboratorioComponent, {windowClass:'vista-laboratorio',scrollable:true, ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
			this.modalRegistroLaboratorio.componentInstance.laboratorio = res_laboratorio;
			this.modalRegistroLaboratorio.componentInstance.usuario = this.usuario;
			//this.modalRegistroLaboratorio.componentInstance.configuracion_pagina = this.configuracion_pagina.object;
			this.modalRegistroLaboratorio.componentInstance.alTerminar.subscribe(async (res) => {
				if(res){
					if(!res.tiene_error){
						this.modalRegistroLaboratorio.close();
					}
				}else{
					this.modalRegistroLaboratorio.close();
				}
			});
		});
  }
}
