import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { BaseComponent } from 'src/app/models/base-component';
import { Util } from 'src/app/utils/utils';

@Component({
  selector: 'app-vista-laboratorio',
  templateUrl: './vista-laboratorio.component.html',
  styleUrls: ['./vista-laboratorio.component.css']
})
export class VistaLaboratorioComponent extends BaseComponent implements OnInit {

  laboratorio:any={paciente:{cliente:{}},pruebas:[]};
  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();
  util:any=Util;

  constructor(
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public usuarioService:UsuarioService,
    public modalService: NgbModal) { 
      super(persistenciaService,modalService,generalService,usuarioService);
    }

  ngOnInit(): void {
  }

  cerrarComponente(){
    this.alTerminar.emit();
  }

}
